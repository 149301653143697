body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@import 'https://cdn.jsdelivr.net/npm/bootstrap@4.3.1/dist/css/bootstrap.min.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mtb30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.ptb30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.page-wrap {
  height: 100%;
  display: flex;
  direction: horizontal;
}

.main-content {
  padding: 50px;
}

.page-wrapper-box {
  height: auto;
  max-width: 88vw;
}

.sidebar {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.flex-horiz {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}

#preview {
  max-width: 80vw;
}


@media only screen and (max-width: 780px) {
  .main-content {
    padding: 10px;
  }
  .page-wrapper-box {
    max-width: 96vw;
  }
  h1 {
    font-size: 30px;
  }
}