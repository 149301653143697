.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#results-torrent {  display: none;  }

#no-results {   display: none;   }

#finished {  display: none;  }


#uploader {
  width: 400px;
  height: 45px;
}

#myProgress {
  width: 100vw;
  height: 30px;
  background-color: rgb(220, 220, 220);
  max-width: 500px;
}

/* Buttons */
#submit-torrents, #reload,#get-magnets, #log { display: none; }

#myBar {
  width: 0%;
  height: 30px;
  background-color: rgb(153, 224, 153);
  transition: all 1s cubic-bezier(0.1, 0.26, 0.45, 0.94);
}



.lds-ring {
  display: none;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 4px solid rgb(30, 30, 30);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(30, 30, 30) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {margin-bottom: 100px;}

.flex-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 10px;
  }

.flex-horiz {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
}

.colorbox {
  width: 215px;
  overflow: hidden;
  border-width: 2px;
  border-color: grey;
  border: 1px solid grey;
  padding: 15px;
}

.color {
  position: relative;
  width: 100%;
  height: 100px;
  background-color: lightblue;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;

  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.color > p {
  text-align: center;
  display: none;
}

.copy {
  width: 100%;
  margin-bottom: 4px;
}

h5 {
  text-transform: capitalize;
  text-overflow: clip;
  white-space: nowrap;
}

#name-form {
  max-width: 500px;
}

.color-full {
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  pointer-events: none;
  display: none;
}

#btn-name {  border-top-left-radius: 0px; border-bottom-left-radius: 0px;   }

#pName { border-right: 0px;}

.colorbox > p {cursor: pointer;}

.empty {display: none}

.torrent-list {
  width: 95vw;
}

.tlist-item {
  white-space:nowrap;
}

.sleepbtn {
  width: 300px;
  height: 300px;
  border-radius: 300px;
}